import React from "react";
import Footer from "./sub/Footer";
import Header from "./sub/Header";
import style from "./../styles/home.module.css";
import product1 from "./../assets/pipeline.png";
import product2 from "./../assets/fitting.png";
import product3 from "./../assets/valve.png";
import product4 from "./../assets/flanges.png";
import product5 from "./../assets/fasteners.png";
import product6 from "./../assets/gasket.png";
import product7 from "./../assets/bolt.png";
import product8 from "./../assets/pump.png";
import product9 from "./../assets/generator.png";
import product10 from "./../assets/lifting.png";
import arrow from "./../assets/arrow.png";
import about from "./../assets/about.png";
import special from "./../assets/special.png";
import project1 from "./../assets/project1.png";
import project2 from "./../assets/project2.png";
import project3 from "./../assets/project3.png";
import project4 from "./../assets/project4.png";
import project5 from "./../assets/project5.png";
import project6 from "./../assets/project6.png";
import client1 from "./../assets/client1.png";
import client2 from "./../assets/client2.png";
import client3 from "./../assets/client3.png";
import client4 from "./../assets/client4.png";
import client5 from "./../assets/client5.png";
import client6 from "./../assets/client6.png";
import sub from "./../assets/sub.png";
export default function Home() {
  return (
    <div>
      <Header />
      <section className={style.hero}>
        <p>
          Let us help your business by purchasing our best quality products. Partner with us as we provide the best procurement and engineering services you've ever had. The products of our manufacturers are internationally certified.
        </p>
        <button>LEARN MORE</button>
      </section>
      <section className={style.brief}>
        <h3>Our Products</h3>
        <div>
          <nav>
            <img src={product1} alt="" />
            <b>Pipes</b>
            {/* <p>Lorem ipsum dolor amet consectetur</p> */}
            <img src={arrow} alt="" />
          </nav>
          <nav>
            <img src={product2} alt="" />
            <b>Fittings</b>
            {/* <p>Lorem ipsum dolor amet consectetur</p> */}
            <img src={arrow} alt="" />
          </nav>
          <nav>
            <img src={product3} alt="" />
            <b>Valves</b>
            {/* <p>Lorem ipsum dolor amet consectetur</p> */}
            <img src={arrow} alt="" />
          </nav>
          <nav>
            <img src={product4} alt="" />
            <b>Flanges</b>
            {/* <p>Lorem ipsum dolor amet consectetur</p> */}
            <img src={arrow} alt="" />
          </nav>
          <nav>
            <img src={product5} alt="" />
            <b>Fasteners</b>
            {/* <p>Lorem ipsum dolor amet consectetur</p> */}
            <img src={arrow} alt="" />
          </nav>
          <nav>
            <img src={product6} alt="" />
            <b>Gaskets</b>
            {/* <p>Lorem ipsum dolor amet consectetur</p> */}
            <img src={arrow} alt="" />
          </nav>
        </div>
      </section>
      <section className={style.about}>
        <div>
          <h3>About Kemej Icons International</h3>
          <p>
            We are a company driven by growth perceptive. Kemej Icons International is an indigenous company in the oil and gas sector. Our products are procured and used in Oil & Gas, Petrochemical, Construction, Water, etc. Our company provides Procurement and project management consultancy service. We work with prospects to provide innovative solutions that will satisfy individuals. To foster an inclusive, innovative, and creative workplace culture, we bring our entire selves to work.
          </p>
          <p>
            We are confident that our team of technical professionals and our whole network can offer value to your project, regardless of the project's size or location.
          </p>
        </div>
        <figure>
          <h3>About Kemej Icons International</h3>
          <img src={about} alt="" />
        </figure>
      </section>
      <section className={style.products}>
        <h3>Products</h3>
        <div>
          <nav>
            <img src={product1} alt="" />
            <b>Pipes</b>
            <p>
              Our pipeline solutions can meet your expectations regardless of whether you need to install a network in physically demanding terrain, transfer severely corrosive liquids under high pressure, or both.
            </p>
          </nav>
          <nav>
            <img src={product2} alt="" />
            <b>Fittings</b>
            <p>
             We make sure to employ a variety of fitting designs to produce the most exemplary results because fittings are used to join and reroute pipes and components to construct complete plumbing systems.
            </p>
          </nav>
          <nav>
            <img src={product3} alt="" />
            <b>Valves</b>
            <p>
              Quality valves are essential tools in the oil and gas sector. We use considerable knowledge and experience to produce valves that can manage and limit all risks involved in regulating the flow processes.
            </p>
          </nav>
          <nav>
            <img src={product4} alt="" />
            <b>Flanges</b>
            <p>
              We offer excellent flanges of different sizes and flange management services that come with correct assembly, tensioning bolt joints, and torquing. 
            </p>
          </nav>
          <nav>
            <img src={product5} alt="" />
            <b>Fasteners</b>
            <p>
              Quality fasteners are vital tools in the oil and gas industry, and we provide only the best oil and gas industry fasteners for construction and maintenance. 
            </p>
          </nav>
          <nav>
            <img src={product6} alt="" />
            <b>Gaskets</b>
            <p>
              Our services include only the best tools, including our custom gaskets. We provide gaskets of all shapes & sizes and different types like fire tube gaskets to withstand high temperatures, ring joints gaskets, compressor engine gaskets and more.
            </p>
          </nav>
          <nav>
            <img src={product7} alt="" />
            <b>Stud Bolts And Nuts</b>
            <p>
              Having supplied numerous tools and different equipment in the oil and gas industry, we are very experienced and only give you the best quality. 
            </p>
          </nav>
          <nav>
            <img src={product8} alt="" />
            <b>Centrifugal Pumps</b>
            <p>
              Centrifugal pumps are the most common in the oil and gas sector, where safety is required. 
            </p>
          </nav>
          <nav>
            <img src={product9} alt="" />
            <b>Diesel And Gas Generators</b>
            <p>
              We procure power generator solutions that have numerous applications in the oil and gas industry, as well as deliver your diesel and gas generators according to your specifications. 
            </p>
          </nav>
          <nav>
            <img src={product10} alt="" />
            <b>Lifting Materials</b>
            <p>
              We supply an extensive array of lifting equipment to suit different applications. We offer only high-technology lifting materials essential for construction operations and can safely support a heavy load. 
            </p>
          </nav>
        </div>
      </section>
      <section className={style.special}>
        <figure>
          <h3>
            What Makes Us <br /> Special{" "}
          </h3>
          <img src={special} alt="" />
        </figure>
        <div>
          <h3>
            What Makes Us <br /> Special{" "}
          </h3>
          <p>
            We offer superior engineering, procurement, maintenance, repair, support and consultancy services that can help you improve your performance operations and efficiency.
          </p>
        </div>
      </section>
      <section className={style.projects}>
        <h3>Our Projects</h3>
        <div>
          <img src={project1} alt="" />
          <img src={project2} alt="" />
          <img src={project3} alt="" />
          <img src={project4} alt="" />
          <img src={project5} alt="" />
          <img src={project6} alt="" />
        </div>
      </section>
      <section className={style.clients}>
        <h3>Our Clients</h3>
        <div>
          <img src={client1} alt="" />
          <img src={client2} alt="" />
          <img src={client3} alt="" />
          <img src={client4} alt="" />
          <img src={client5} alt="" />
          <img src={client6} alt="" />
        </div>
      </section>
      <section className={style.sub}>
        <img src={sub} alt="" />
        <b>Subscribe to Our Newsletter</b>
        <p>
          join our subscribers list to get the latest news, updates <br /> and
          special offers delivered directly to your email
        </p>
        <label>
          <input type="text" placeholder="Enter Your Email" />
          <button>SUBSCRIBE</button>
        </label>
      </section>
      <Footer />
    </div>
  );
}
