import React from 'react'
import Footer from './sub/Footer'
import Header from './sub/Header'
import style from './../styles/product.module.css'
import product1 from "./../assets/product1.png";
import product2 from "./../assets/product2.png";
import product3 from "./../assets/product3.png";
import product4 from "./../assets/product4.png";
import product5 from "./../assets/product5.png";
import product6 from "./../assets/product6.png";

export default function Product() {
  return (
    <div>
      <Header />
      <section className={style.hero}>
        <h3>Our Services</h3>
      </section>
      <section className={style.services}>
        <h3>Our Services</h3>
        <nav>
          <figure>
            <img src={product1} alt="" />
          </figure>
          <div>
            <b>Thermal Power</b>
            <p>
              As the world further advances, the need for energy to improve efficiency and protect the environment and individual lifestyle increases. Thermal energy provides the solutions to overcome these global challenges with a greener form of energy which Kemej icons international make available for use. 
            </p>
            <p>
              We have partnered with leading energy procurement, construction and maintenance providers to provide services according to the demands and products in the energy market. We have utilized our high technologies as well as our project management teams and experts to optimize our energy production services for safe, reliable, productive and environmentally friendly thermal energy. 
            </p>
            <p>
              We help you increase productivity and performance operations using our low carbon solution services. We also develop our services for diesel-fired power plants,  gas, bio, and energy from waste plants.
            </p>
          </div>
        </nav>
        <nav>
          <div>
            <b>Oil Platforms</b>
            <p>
              We specialize in providing quality services that include repair, consultation and support for international oil and gas industries. 
            </p>
            <p>
              We consistently maintain customer satisfaction by helping our clients benefit from quality and value. Our services are operated in a safe way for the environment and provide flexible support and solutions for any repair or upgrade to the rig market. 
            </p>
            <p>
              Our comprehensive project management always takes advantage of the new technologies striving to improve our processes and overall efficiency to give you the best service according to your needs.
            </p>
          </div>
          <figure>
            <img src={product2} alt="" />
          </figure>
        </nav>
        <nav>
          <figure>
            <img src={product3} alt="" />
          </figure>
          <div>
            <b>Gas Flaring</b>
            <p>
              Gas flaring services are essential requirements of industrial plants like chemical plants and petroleum refineries in the oil and gas sector, which is why we prioritize these factors and offer a routine consultation and engineering service to help relieve pressure and prevent hazardous risks like an explosion from venting a large number of reactive gases and energy. 
            </p>
            <p>
              Our professional engineers and technicians team has access to technologies that optimize industrial energy efficiency and production while also providing the support used to reduce flares in the oil and gas industries and remove dangerous gases, pushing for a safer industrial environment.c
            </p>
          </div>
        </nav>
        <nav>
          <div>
            <b>Oil Pump</b>
            <p>
              We dedicate our entire operation to providing quality designed pumping equipment manufactured internationally by world-leading agencies. When talking about procurement and supply chain management, we offer onshore and offshore oil pump solutions. 
            </p>
            <p>
              Our services are strict about maintaining our customer's trust and ensuring the quality of our services. We provide both procurement and engineering services that comes alongside consultation, management and maintenance services.
            </p>
            <p>
              Our procurement packages come with extensive repair services and a diverse application of midstream crude transportation or downs stream refining process. We offer procurements of different pumps and maintenance services to help limit the risk of hazardous gases around work sites where pumps will be used.
            </p>
          </div>
          <figure>
            <img src={product4} alt="" />
          </figure>
        </nav>
        <nav>
          <figure>
            <img src={product5} alt="" />
          </figure>
          <div>
            <b>Oil Transportation</b>
            <p>
              If you require a detail-oriented and well-organized international and domestic oil transportation solution, Kemej icons international offers the procurement and delivery of oil and oil products.
            </p>
            <p>
              We understand how delicate and essential these products are, so we avoid toxicity, ignition, spontaneous combustion and other hazards. We follow the legal regulations of transportation and provide safe and fast delivery services.
            </p>
          </div>
        </nav>
        <nav>
          <div>
            <b>Gas Transportation</b>
            <p>
              We provide gas processing and transportation services. Unlike any other procurement company, we utilize safe domestic and international transportation routes to deliver gas commodities, gas equipment, liquefied natural, petroleum gas, elemental gases, and non-combustible gases and mixtures from interstate pipeline connections. 
            </p>
            <p>
              We understand the technicalities involved during transportation and follow the regulations of cargo transportation to ensure your products get to you quickly and in safe conditions.
            </p>
          </div>
          <figure>
            <img src={product6} alt="" />
          </figure>
        </nav>
      </section>
      <Footer />
    </div>
  );
}
