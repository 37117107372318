import React from 'react';
import Footer from './sub/Footer';
import Header from './sub/Header';
import style from './../styles/about.module.css';
import about from './../assets/about.png';
import about1 from './../assets/about1.png';
import about2 from './../assets/about2.png';
import about3 from './../assets/about3.png';
import client1 from './../assets/client1.png';
import client2 from './../assets/client2.png';
import client3 from './../assets/client3.png';
import client4 from './../assets/client4.png';
import client5 from './../assets/client5.png';
import client6 from './../assets/client6.png';
// import team1 from './../assets/team1.png';
// import team2 from './../assets/team2.png';
// import team3 from './../assets/team3.png';
// import team4 from './../assets/team4.png';
import string from './../assets/string.png';
export default function About() {
  return (
    <div>
      <Header />
      <section className={style.hero}>
        <h3>About Us</h3>
      </section>
      <section className={style.about}>
        <div>
          <h3>About Kemej Icons International</h3>
          <p>
            Kemej icons international is a world-leading company in
            the oil and gas sector. We have integrated the experience
            and Knowledge accumulated by our teams of expert
            technicians, engineers, and support staff to provide the
            service quality that matches your every demand.
          </p>
          <p>
            Our high-quality products are manufactured and certified
            internationally, providing excellent products from the
            USA, United Nations and other countries.
          </p>
          <p>
            We offer superior engineering, procurement, maintenance,
            repair, support and consultancy services that can help you
            improve your performance operations and efficiency.
          </p>
        </div>
        <figure>
          <h3>About Kemej Icons International</h3>
          <img src={about} alt="" />
        </figure>
      </section>
      <section className={style.history}>
        <h3>Our History</h3>
        <nav>
          <figure>
            <img src={about1} alt="" />
          </figure>
          <div>
            <p>
              Kemej icons international was founded in 2008 and became
              a distinctive indigenous procurement and engineering
              service company in the Oil and gas sector. This company
              was established to provide procurement of oil and gas
              equipment, construction and water equipment, and civil
              equipment as well as superior support, and consultancy
              services. Kemej icons is renowned for its excellent
              repair and maintenance of oil and gas pipelines
              integrated by a team of expert engineers, technicians,
              and support staff.
            </p>
          </div>
          <img src={string} alt="" />
        </nav>
        <nav>
          <div>
            <p>
              Kemej icons is renowned for its excellent repair and
              maintenance of oil and gas pipelines integrated by a
              team of expert engineers, technicians, and support
              staff. Kemej icons has established multiple reliable
              supply chains from foreign countries like the USA,
              United Kingdom, Italy, and other major countries to
              provide a reputable procurement service.
            </p>
          </div>
          <figure>
            <img src={about2} alt="" />
          </figure>
          <img src={string} alt="" />
        </nav>
        <nav>
          <figure>
            <img src={about3} alt="" />
          </figure>
          <div>
            <p>
              We have also established relations all over the world,
              rapidly expanding into Western and African countries and
              partnering with high-end companies like: FBV Inc, USA;
              FZV inc; The Allied group Italy; Capra Valves; FAD
              flanges Italy; Lonestar; Cartec; Benkan fitting; GPT;
              Garlock gasket; Lamons; Flexitallic; TPCO pipes;
              Baosteel pipes; Nippon steel; Staycold fridge; Hubbel
              light; Cummins; Speed Queen; Jeil E&S; Speroni; Alfa
              Laval; Grating manufacturing company; Acoustic ceiling
              manufacturing company.
            </p>
          </div>
        </nav>
      </section>
      <section className={style.clients}>
        <h3>Our Clients</h3>
        <div>
          <img src={client1} alt="" />
          <img src={client2} alt="" />
          <img src={client3} alt="" />
          <img src={client4} alt="" />
          <img src={client5} alt="" />
          <img src={client6} alt="" />
        </div>
      </section>
      <section className={style.statement}>
        <b>Mission</b>
        <p>
          Our dedication is to provide the highest quality products
          and services in all areas including oil and gas,
          construction, water, etc, using our excellent and reliable
          supply chains and delivery services as well as providing
          effective and valuable procurement and engineering solutions
          strictly in line with our client's demands and
          specifications, as a result, establishing complete trust and
          satisfaction from our clients.
        </p>
        <b>Vision</b>
        <p>
          We are a company driven by the growth perspective, we strive
          to become the number one oil and gas procurement and
          engineering company. We are not just confident in our
          ability to provide solutions to your specifications, but we
          are competent and move to be the top reliable and trusted
          choice to every client and organization in the oil and gas
          sector.
        </p>
      </section>
      <section className={style.team}>
        <h3>Our Team</h3>
        <div>
          <nav>
            {/* <img src={team1} alt="" /> */}
            <p>
              Paul Olu <br /> MD/CEO
            </p>
          </nav>
          <nav>
            {/* <img src={team2} alt="" /> */}
            <p>
              Paula Olu <br /> President
            </p>
          </nav>
          <nav>
            {/* <img src={team3} alt="" /> */}
            <p>
              Emma Ope <br /> CTO
            </p>
          </nav>
          <nav>
            {/* <img src={team4} alt="" /> */}
            <p>
              Joy Smart <br /> HR
            </p>
          </nav>
        </div>
      </section>
      <Footer />
    </div>
  );
}
